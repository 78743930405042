<template>
    <el-dialog :visible.sync="formDialog" title="添加" :close-on-click-modal="false" @close="closeForm">
        <el-form ref="dataForm" :model="dataForm" :rules="ruleForm" label-width="100px" class="data-form">
            <el-form-item label="标签名" prop="name">
                <el-input v-model="dataForm.name" placeholder="标签名"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm()">提交</el-button>
                <el-button @click="resetForm()">重置</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
import { addCookBookTags } from '@/utils/servers/cookBookTags.js';
export default {
    props: ['addFormDialog'],
    data() {
        return {
            formDialog: this.addFormDialog,
            dataForm: {
                name: ''
            },
            ruleForm: {
                name: [{ required: true, trigger: 'blur' }]
            }
        };
    },
    methods: {
        submitForm() {
            addCookBookTags(this.dataForm).then(res => {
                var data = res;
                if (data.code == 1) {
                    this.$message.success(data.msg);
                    this.closeForm();
                    this.$emit('getCookBookTagsList');
                } else {
                    this.$message.error(data.msg);
                }
            });
        },
        resetForm() {
            this.$refs.dataForm.resetFields();
        },
        closeForm() {
            this.$emit('closeForm', 'add');
        }
    }
};
</script>

<style lang="less" scoped>
.data-form {
    width: 400px;
}
</style>
