import request from '@/utils/request';

export function getCheckCookBookTags(){
  return request({
    url: '/cook_book_tags/check_cook_book_tags',
    method: 'get',
  })
}

export function getCookBookTagsList(data) {
    return request({
        url: '/cook_book_tags/list',
        method: 'post',
        data: data
    });
}

export function getCookBookTagsInfo(id) {
    return request({
        url: '/cook_book_tags/info/' + id,
        method: 'get',
    });
}

export function addCookBookTags(data) {
    return request({
        url: '/cook_book_tags/add',
        method: 'post',
        data: data
    });
}

export function editCookBookTags(id, data){
    return request({
        url: '/cook_book_tags/edit/' + id,
        method: 'put',
        data: data
    });
}

export function delCookBookTags(data) {
    return request({
        url: '/cook_book_tags/del',
        method: 'post',
        data: data
    });
}
